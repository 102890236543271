/* Mobile first queries */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* font-family: 'Roboto', sans-serif; */
/* font-family: 'Source Code Pro', monospace; */

html {
  overflow-x: hidden;
  font-size: 62.5%;
  /* (62.5/100) * 16px = 10px */
  -webkit-text-size-adjust: none;
  /* for iOS Safari */
  text-size-adjust: none;
  /* for other mobile browsers */
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  padding: 0;
  margin: 0;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-size: 1.6rem;
  overflow-x: hidden;
  color: #dddddd;
  font-family: "Source Code Pro", monospace;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
  position: relative;
}

img {
  display: block;
  max-width: 100%;
}

menu:not(article menu),
ol:not(article ol),
ul:not(article ul) {
  list-style: none;
}

menu,
ol,
ul {
  padding-left: 0;
}

article ol,
article ul {
  list-style-position: inside;
}

a {
  text-underline-position: under;
  /* Places underlines below the descenders */
  text-decoration-thickness: 8;
  /* Sets the thickness as a percentage of the font size */
}

:focus:not(:focus-visible) {
  outline: none;
}

label,
button,
select,
summary,
[type="radio"],
[type="submit"],
[type="checkbox"] {
  cursor: pointer;
}

/* BUTTONS START */
.primary-button {
  height: 3rem;
  border: none;
  outline: none;
  padding: 0 2rem;
  margin: 2rem 0;
  background-color: transparent;
  border: 1px solid #dddddd;
  color: #dddddd;
  font-family: "Roboto", sans-serif;
}

.primary-button:hover {
  background-color: #f05454;
  outline-color: #dddddd;
  transition: all 200ms;
  border: 1px solid #f05454;
}
/* BUTTONS END */

/* MAIN CONTAINERS STARRT */
.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container,
.navbar,
.footer-content {
  width: 90%;
}
/* MAIN CONTAINERS END */

/* BANNER CONTAINER START */
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.banner div {
  width: 100%;
}

.banner h1 {
  font-size: 4rem;
  width: 100%;
}

.banner p {
  color: #f05454;
  width: 100%;
}

.banner span {
  color: #30475e;
}

.banner-button {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
}

.banner-button button {
  height: 3rem;
  border: none;
  outline: none;
  padding: 0 2rem;
  background-color: transparent;
  border: 1px solid #dddddd;
  color: #dddddd;
  font-family: "Roboto", sans-serif;
}

.banner-button button:hover {
  background-color: #f05454;
  outline-color: #dddddd;
  transition: all 200ms;
  border: 1px solid #f05454;
}
/* BANNER CONTAINER END */

/* NAVBAR CONTAINER START */
.navbar {
  position: fixed;
  top: 0;
  /* left: 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: #222831;
  /* padding: 0 2rem; */
  height: 5rem;
  z-index: 18;
}

.navbar-links {
  display: none;
}

.navbar-logo a {
  color: #f05454;
  text-decoration: none;
}

.navbar-logo p {
  color: #f05454;
  font-size: 2rem;
}

.navbar-links-mobile {
  position: fixed;
  top: 4rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #222831;
  width: 100%;
  height: 100%;
}

.navbar-links-mobile a {
  text-align: center;
  color: #dddddd;
  text-decoration: none;
  margin: 1rem 0;
  padding: 2rem 0;
  width: 100%;
}

.navbar-links-mobile a:hover {
  background-color: #f05454;
}

.hamburger-icon {
  position: relative;
}

.hamburger-icon i {
  font-size: 2.5rem;
}

.hamburger-icon i:hover {
  color: #f05454;
  transition: 200ms;
}
/* NAVBAR CONTAINER END */

/* ABOUT CONTAINER START */
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 5rem;
}

.about h2 {
  color: #f05454;
  margin-top: 5rem;
}

.about-content-container {
  margin: 5rem 0;
}

.about-content-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 3rem 0;
  border: 1px solid #30475e33;
  padding: 1rem;
  min-height: 12rem;
}

.about-content-div:hover {
  background-color: #222831;
  border: 1px solid #30475e;
  color: #f05454;
  /* transform: scale(1.005); */
  transition: all 200ms;
}

.about-content-div h3 {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 500;
}

.about-content-div p {
  font-weight: 300;
  font-size: 1.5rem;
}

.about-skills,
.about-skills p {
  margin: 2rem 0;
}

.about-me-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-me-skill-container {
  width: 100%;
}

.about-me-skill-container i {
  font-size: 2.5rem;
  margin-right: 1rem;
}

.about-me-pogression-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
}

.about-me-pogression-bar p:first-of-type {
  width: 10rem;
}
/* ABOUT CONTAINER END */

/* PROJECT CONTAINER START */
.project {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.project h2 {
  margin: 5rem 0;
  color: #f05454;
  /* padding-bottom: 1rem;
  text-align: center;
  width: 100%;
  border-bottom: 3px solid #f05454; */
}

.project-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  width: 100%;
}

.project-button {
  height: 4rem;
  min-width: 11.3rem;
  color: #dddddd;
  background-color: transparent;
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}

.project-button-active {
  height: 4rem;
  min-width: 11.3rem;
  color: #dddddd;
  background-color: #30475e;
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}

.projects-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
  gap: 1rem;
}

.projects-container-image {
  /* margin-bottom: 2.5rem; */
  width: 33.7rem;
  height: 48rem;
  cursor: pointer;
}

.projects-container-image div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 30%,
    black 100%
  );
  color: #dddddd;
}

.projects-container-image div p {
  text-align: center;
}
/* PROJECT CONTAINER END */

/* CONTACT FORM SECTION START */
.contact {
  width: 100%;
}

.contact h2 {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
  color: #f05454;
}

.contact-text {
  margin-bottom: 3rem;
}

.contact-text p {
  text-align: left;
  font-size: 2rem;
}

.contact-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-form-container form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  border: 1px solid #30475e;
  padding: 1rem;
  background-color: #222831;
}

.contact-form-container input {
  background-color: transparent;
  width: 100%;
}

.contact-form-container label {
  color: #f05454;
}

.contact-form-container input {
  border: 1px solid #30475e;
  background-color: #30475e;
  margin: 1rem 0;
  height: 3rem;
  padding-left: 1rem;
  outline: none;
  color: #dddddd;
  box-shadow: inset 0px 0px 5px 0px #222831;
}

.contact-form-container input::placeholder,
.contact-form-container textarea::placeholder {
  color: #dddddd55;
}

.contact-form-container textarea {
  border: 1px solid #30475e;
  outline: none;
  background-color: #30475e;
  width: 100%;
  height: 15rem;
  margin-top: 1rem;
  color: #dddddd;
  padding: 1rem;
  font-size: 1.4rem;
  box-shadow: inset 0px 0px 5px 0px #222831;
}

.contact-form-container div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 2rem 0 1rem 0;
}

.contact-form-container label {
  position: relative;
  width: 100%;
}
.contact-form-container span {
  color: #f05454;
  font-size: 1.3rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

.contact-button {
  height: 4rem;
  min-width: 11.3rem;
  color: #dddddd;
  background-color: transparent;
  border: 1px solid #30475e;
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}

.contact-button:hover {
  color: #dddddd;
  background-color: #30475e;
}
/* CONTACT FORM SECTION END */

/* MODAL START */
.modal-background {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px) brightness(0.3);
}

.modal {
  height: 100vh;
  width: 100vw;
  background-color: #30475e;
  border-radius: 5px;
  z-index: 20;
  scrollbar-width: none;
}

.modal::-webkit-scrollbar {
  display: none;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
  padding: 0 1.3rem;
}

.modal-header i {
  font-size: 2.5rem;
  color: #dddddd;
}

.modal-header i:hover {
  color: #f05454;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 94%;
  width: 100%;
}

.modal-banner {
  /* border: 1px solid #f05454; */
  height: auto;
  width: 100%;
  padding: 0 1.2rem 1.2rem;
}

.modal-banner img {
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.modal-title {
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
  width: 100%;
}

.modal-description {
  width: 100%;
  /* border: 1px solid white; */
  padding: 0 1rem;
}

.modal-title h3 {
  color: #f05454;
}

.modal-description p {
  text-align: left;
  height: 18rem;
  border: 1px solid #30475e;
  outline: none;
  background-color: #30475e;
  width: 100%;
  /* height: 15rem; */
  margin-top: 1rem;
  color: #dddddd;
  padding: 1rem;
  font-size: 1.4rem;
  box-shadow: inset 0px 0px 5px 0px #222831;
  border-radius: 3px;
  overflow-y: scroll;
}

.modal-image-carousel {
  display: flex;
  overflow-x: scroll;
  margin: 0 1.2rem 1.2rem;
  border-radius: 3px;
  /* border: 1px solid white; */
}

.modal-image-carousel img {
  width: 10rem;
  height: 16rem;
  margin-right: 1rem;
}

.modal-tech {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 2rem 1.2rem;
}

.modal-tech p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  min-width: 10rem;
  padding: 0.2rem 1rem;
  background-color: #dddddd;
  color: #30475e;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  margin: 0 1rem 1rem 0;
  height: 2rem;
  font-size: 1.4rem;
  font-weight: 300;
}
/* MODAL END */

/* FOOTER START */

.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 10rem;
}

.footer-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: #dddddd;
  color: #222831;
  padding: 5rem 1rem 1rem 1rem;
  padding-bottom: 5rem;
}

.footer-icon-container i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  height: 5rem;
  width: 5rem;
}

.footer-icon-container a {
  color: #222831;
  text-decoration: none;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
/* FOOTER END */

/* DYNAMIC PAGE START */
.dynamic-page {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dynamic-page h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
}

.dynamic-page-banner {
  width: 100%;
  height: 100%;
  margin: 1rem 0 1rem 0;
  border-radius: 3px;
}

.dynamic-page-banner img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.dynamic-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5rem;
}

.dynamic-header h1 {
  font-size: 2rem;
}

.dynamic-header .primary-button {
  width: 12rem;
}

.dynamic-page-carousel {
  display: flex;
  align-items: center;
  overflow: scroll;
  border-radius: 3px;
}

.dynamic-page-carousel img {
  width: 9rem;
  height: 15rem;
  margin-right: 1rem;
}

.dynamic-page-description {
  width: 100%;
}

.dynamic-page-description p {
  text-align: left;
  max-height: 18rem;
  min-height: 100%;
  border: 1px solid #30475e;
  outline: none;
  background-color: #30475e;
  width: 100%;
  margin-top: 1rem;
  color: #dddddd;
  padding: 1rem;
  font-size: 1.3rem;
  box-shadow: inset 0px 0px 5px 0px #222831;
  border-radius: 3px;
  overflow-y: scroll;
}

.dynamic-page-tech {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
  padding-bottom: 1rem;
  /* overflow: scroll; */
  border-bottom: 1px solid #dddddd;
}

.dynamic-page-tech p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  min-width: 7rem;
  /* max-width: 10rem; */
  padding-top: 0.65rem;
  background-color: #dddddd;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  border-radius: 2px;
  margin: 0 1rem 1rem 0;
  height: 3rem;
  font-size: 1.3rem;
  font-weight: 300;
}

/* DYNAMIC PAGE END */

/* 404 PAGE START */
.container-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  height: 55vh;
  width: 100%;
  /* background-color: #dddddd; */
  color: #dddddd;
}

.container-404 h1 {
  font-size: 2rem;
  margin: 1rem 0;
}

.container-404 p {
  font-size: 1.5rem;
}

.container-404 a {
  color: #dddddd;
  text-decoration: none;
}

.container-404 a:hover {
  text-decoration: underline;
}
/* 404 PAGE END */

/* LOADER-SPINNER START */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background: #fcf; */
  background: #dddddd;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  /* border: 1px solid blue; */
  z-index: 1000;
  background: #222831;
  color: #dddddd;
}
/* LOADER-SPINNER END */

/* EXTRA CLASSES START */
.visibility-none {
  visibility: hidden;
}

.display-none {
  display: none;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.arrow-up-icon {
  position: absolute;
  top: -2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid white;
  width: 5rem;
  height: 5rem;
  background-color: #222831;
  z-index: 1;
}

.arrow-up-icon:hover {
  background-color: #f05454;
  border: 1px solid #f05454;
  cursor: pointer;
}

.arrow-up-icon i {
  font-size: 2rem;
}
.arrow-up-icon p {
  font-size: 1rem;
}

.arrow-up-icon a {
  color: unset;
  text-decoration: none;
}

.need-a-website {
  position: absolute;
  bottom: 5rem;
  font-size: 1.3rem;
  color: #222831;
}

.need-a-website a {
  text-decoration: none;
  color: #222831;
  cursor: pointer;
}

.SimpleCodesPH {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  padding: 1rem 0;
}

.SimpleCodesPH a {
  text-decoration: none;
  color: #dddddd;
  cursor: pointer;
}
/* EXTRA CLASSES END */

/* POGRESS BAR LOOADER START */
@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 60px 0;
  }
}

@keyframes auto-progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.progress-bar {
  background-color: #1a1a1a;
  height: 1.5rem;
  width: 200px;
  /* margin-right: 1rem; */
  box-shadow: 0 1px 5px #00000065 inset, 0 1px 0 #44444444;
}

.progress-bar-bgcolor {
  background-color: transparent;
}

.progress-bar-inner {
  display: block;
  height: 1.5rem;
  width: 0%;
  background-color: #30475e;
  position: relative;
  animation: auto-progress 3000ms linear;
  animation-iteration-count: 1;
}

.progress-bar-inner {
  width: 100%;
}
/* POGRESS BAR LOOADER END */

/* Larger than mobile */
@media (min-width: 400px) {
  .projects-container {
    gap: 2rem 0;
  }

  .project-button-active {
    min-width: 13.3rem;
  }
  .projects-container-image {
    width: 35.7rem;
  }
}

/* Larger than phablet */
@media (min-width: 550px) {
  .navbar-links-mobile {
    box-shadow: 0px 20px 10px 1px #1a1a1a;
    width: 70%;
  }

  .navbar-links-mobile a {
    font-size: 2rem;
  }

  .banner div {
    width: 100%;
  }
  .about-content-container {
    margin: unset;
  }

  .about-me-container {
    flex-direction: row;
    height: 100%;
  }

  .about-me img {
    max-width: unset;
    width: 23.5rem;
    margin-right: 2rem;
  }

  .about-skills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .projects-container {
    justify-content: flex-start;
    flex-direction: unset;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
  }

  .projects-container-image {
    width: 23.5rem;
    height: 30rem;
  }

  .projects-container-image p {
    display: none;
  }

  .modal {
    height: 95%;
    width: 80%;
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .navbar-links-mobile {
    width: 50%;
    box-shadow: 0px 20px 10px 1px #1a1a1a;
  }

  .projects-container-image {
    width: 32.5rem;
    height: 48rem;
  }

  .projects-container-image p {
    display: block;
  }

  .modal {
    width: 60%;
  }

  .modal-container {
    overflow-y: scroll;
  }

  .dynamic-page-carousel img {
    width: 13rem;
    height: 24rem;
    margin-right: 1rem;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .navbar-links {
    display: flex;
  }

  .navbar-links a {
    text-align: center;
    color: white;
    text-decoration: none;
    padding: 1rem;
    width: 10rem;
  }

  .navbar-links a:hover {
    background-color: #f05454;
  }

  .hamburger-icon {
    display: none;
  }

  .about-content-div:nth-of-type(odd) {
    margin-right: 1rem;
  }

  .about-content-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .about-content-div {
    width: 49%;
  }

  .projects-container-image {
    width: 29rem;
    height: 41rem;
  }

  .modal {
    width: 50%;
  }

  .dynamic-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .dynamic-header h1 {
    font-size: 2rem;
    margin-right: 1rem;
  }

  .dynamic-page-carousel img {
    width: 17rem;
    height: 32rem;
    margin-right: 1rem;
  }

  .projects-container {
    gap: 1rem;
  }

  .footer-content {
    width: 50%;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .container,
  .navbar,
  .footer-content {
    width: 50%;
  }

  .navbar {
    padding: unset;
  }

  .banner h1 {
    font-size: 8rem;
  }

  .about-me img {
    width: 35rem;
  }

  .projects-container-image {
    width: 23rem;
    height: 32rem;
  }

  /* .projects-container div {
    margin-right: 1rem;
  } */

  .modal {
    width: 20%;
  }

  .contact-form-container form {
    width: 70%;
  }

  .dynamic-page-banner {
    margin-top: 5rem;
  }

  .container-404 {
    height: 62vh;
  }
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
